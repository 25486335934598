.skills {
  background-color: #f9f9f9;
  padding: 50px 0;
}

.category-tab {
  display: inline-block;
  padding: 15px 30px;
  margin: 10px 5px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #4a90e2;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.category-tab:hover {
  background-color: #3a76c6;
  transform: translateY(-2px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.react-tabs {
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 0 auto;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 40px 0;
  padding: 0;
}

.react-tabs__tab {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  color: #444;
  background-color: #f9f9f9;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.react-tabs__tab:hover {
  color: #4a90e2;
}

.react-tabs__tab--selected {
  color: #fff;
  background-color: #4a90e2;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  margin: 0 auto;
  padding: 30px;
  max-width: 1200px;
  background-color: #fff;
}

.skill-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgb(74, 144, 226);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  position: relative;
  background: white;
}

.skill-card:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-4px);
}

.skill-card img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
}

.skill-card h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #333;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
.skill-card::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
}

.skill-card:hover::before {
  opacity: 1;
}

.skill-card a {
  color: #fff;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
}

.skill-card a:hover {
  text-decoration: underline;
}

.skill-card p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #666;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

.skill-card h6,
.skill-card p {
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: visibility 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
}

.skill-card:hover h6,
.skill-card:hover p {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 767px) {
  .skills {
    padding: 40px 0;
  }

  .category-tab {
    padding: 12px 24px;
    font-size: 16px;
  }

  .react-tabs__tab-list {
    margin: 30px 0;
  }

  .react-tabs__tab {
    padding: 8px 16px;
    font-size: 16px;
  }

  .skills-grid {
    padding: 20px;
  }

  .skill-card {
    padding: 15px;
  }

  .skill-card img {
    margin-bottom: 5px;
  }

  .skill-card h6 {
    font-size: 14px;
  }

  .skill-card p {
    font-size: 12px;
  }
}
